<template>
  <div class="customer-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('customers')"/>

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <CustomerFilterForm
              :values="filters"
              ref="filterForm"
              slot="filter"
            />
          </DataFilter>

          <br />
          <br />
          <v-btn outlined @click="downloadCustomerCsv()">
            <v-icon>mdi-download</v-icon>
            <span>{{ $t("DownloadCustomerCsv") }}</span>
          </v-btn>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template v-slot:item.firstName="{ item }">
              {{ getProfileItemBasics(item.profile, 'firstName') }}
            </template>
            <template slot="item.lastName" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'lastName') }}
            </template>
            <template slot="item.company" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'company') }}
            </template>
            <template slot="item.enabled" slot-scope="{ item }">
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>

            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-show="() => showHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import CustomerFilterForm from '../../components/customer/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ProfileMixin from "@/mixins/ProfileMixin";
import DocumentMixin from '@/mixins/DocumentMixin';

export default {
  name: 'CustomerList',
  servicePrefix: 'Customer',
  mixins: [ListMixin, ProfileMixin, DocumentMixin],
  components: {
    Toolbar,
    ActionCell,
    CustomerFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        {text: this.$i18n.t('customerNumber'), value: 'customerNumber'},
        {text: this.$i18n.t('firstName'), value: 'firstName'},
        {text: this.$i18n.t('lastName'), value: 'lastName'},
        {text: this.$i18n.t('company'), value: 'company'},
        {text: this.$i18n.t('enabled'), value: 'enabled', align: 'center'},
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      selected: [],
      filters: {
        profile: {}
      }
    };
  },
  computed: {
    ...mapGetters('customer', {
      items: 'list'
    }),
    ...mapFields('customer', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('customer', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      clearCustomerData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {}
      };
    },
    refreshData(){
      this.clearCustomerData();
      this.clearProfileData();
      this.onSendFilter();
    },
    downloadCustomerCsv() {
      this.downloadDocument(
          'customers/generateCustomerListCsv',
          "Kundendaten.csv",
          true
      );
    },
  }
};
</script>
