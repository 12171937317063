<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('customerNumber')"
          type="text"
          v-model="item.customerNumber"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('email')"
          type="text"
          v-model="item.email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('firstName')"
          type="text"
          v-model="item.profile.firstName"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('lastName')"
          type="text"
          v-model="item.profile.lastName"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          :label="$t('company')"
          type="text"
          v-model="item.profile.company"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'CustomerFilter',
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    }
  },
};
</script>
